import { React } from "react";
import { useTranslation } from 'react-i18next';

const Sponsors = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="srex-sponsor srex-sponsor-three srex-section wow ud-fade-in-up" data-wow-delay="200ms">
                <div className="container">
                    <div className="srex-sponsor__items wow fadeInUp" data-wow-duration="1.5s">
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/logo_horizontal_white_700.png" alt="RoomMonitor" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/airbnb_logo_blanco.png" alt="Airbnb" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/logo-apartur-blanco-01.png" alt="Apartur" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/ajuntament_barcelona_logo_blanco-01.png" alt="Ajuntament Barcelona" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/Logo AVVAPRO negativo2.png" alt="AVVA" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/logo-ayuntamiento-de-malaga2.png" alt="Ayuntamiento málaga" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/Logo-APTUR-CV2.png" alt="ApturCV" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/Turisme_Comunidad_Valenciana2.png" alt="Comunitad Valenciana Turime" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/madridaloja-blanco.png" alt="Madrid Aloja" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/Alep2.png" alt="Alep" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/colaboradores/logo_horizontal_white_700.png" alt="RoomMonitor" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Sponsors;
