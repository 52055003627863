import { size } from "lodash";
import { React } from "react";
import { useTranslation } from 'react-i18next';

const Destacados = ({ lang }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="srex-service srex-section">
            <div className="container">
                <div className="text-center srex-section__head">
                    <h2 className="srex-section__head__title wow ud-fade-in-up" data-wow-delay="200ms">
                        {t("Destacamos")}
                    </h2>
                </div>
                <div className="srex-info-box">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay="200ms">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="srex-info-box__item__img">
                                        <i className="fa-thin fa-house-heart" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                    </div>
                                    <h2 className="srex-info-box__item__number">
                                        01
                                    </h2>
                                </div>
                                <h3 className="srex-info-box__item__text">
                                    {t("Beneficios")}
                                </h3>
                                <div className="srex-info-box__more">
                                    <a href={"/" + preidioma + t("ventajas")}>
                                        {t("Más información")}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay="400ms">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="srex-info-box__item__img">
                                        <i className="fa-thin fa-handshake" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                    </div>

                                    <h2 className="srex-info-box__item__number">
                                        02
                                    </h2>
                                </div>
                                <h3 className="srex-info-box__item__text">
                                    {t("Socios corporativos")}
                                </h3>
                                <div className="srex-info-box__more">
                                    <a href="">
                                        {t("Más información")}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay="600ms">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="srex-info-box__item__img">
                                        <i className="fa-thin fa-users" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                    </div>

                                    <h2 className="srex-info-box__item__number">
                                        03
                                    </h2>
                                </div>
                                <h3 className="srex-info-box__item__text">
                                    {t("Particulares")}
                                </h3>
                                <div className="srex-info-box__more">
                                    <a href="">
                                        {t("Más información")}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay="800ms">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="srex-info-box__item__img">
                                        <i className="fa-thin fa-seal" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                    </div>
                                    <h2 className="srex-info-box__item__number">
                                        04
                                    </h2>
                                </div>
                                <h3 className="srex-info-box__item__text">
                                    {t("Solicitar el sello")}
                                </h3>
                                <div className="srex-info-box__more">
                                    <a href={"/" + preidioma + t("contacto")}>
                                        {t("Alta")}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay="1000ms">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="srex-info-box__item__img">
                                        <i className="fa-thin fa-message-lines" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                    </div>
                                    <h2 className="srex-info-box__item__number">
                                        05
                                    </h2>
                                </div>
                                <h3 className="srex-info-box__item__text">
                                    {t("Dudas")}
                                </h3>
                                <div className="srex-info-box__more">
                                    <a href={"/" + preidioma + t("contacto")}>
                                        {t("Contáctanos")}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay="1200ms">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="srex-info-box__item__img">
                                        <i className="fa-thin fa-bullseye-pointer" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                    </div>
                                    <h2 className="srex-info-box__item__number">
                                        06
                                    </h2>
                                </div>
                                <h3 className="srex-info-box__item__text">
                                    {t("Misión")}
                                </h3>
                                <div className="srex-info-box__more">
                                    <a href="">
                                        {t("Más información")}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}
export default Destacados;

