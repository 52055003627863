import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const Scripts = ({ lang }) => {

    const { t } = useTranslation();

    const jqueryScript = "/vendor/werespect/jquery/jquery-3.6.0.min.js";

    const scriptSources = [
        "/vendor/werespect/bootstrap/js/popper.min.js",
        "/vendor/werespect/bootstrap/js/bootstrap.min.js",
        "/vendor/werespect/slick-slider/slick.js",
        "/vendor/werespect/wow/wow.min.js",
        "/vendor/werespect/metismenu/metismenu.js",
        "/vendor/werespect/magnific-popup/magnific-popup.min.js",
        "/vendor/werespect/waypoints/waypoints.min.js",
        "/vendor/werespect/counterup/counterup.js",
        "/vendor/werespect/jquery-mixitup/jquery-mixitup.min.js",
        "/vendor/werespect/custom.js",
    ];

    const loadScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Error loading script: ${src}`));
            document.body.appendChild(script);
        });
    };

    useEffect(() => {

        const loadScripts = async () => {
            try {
                await loadScript(jqueryScript);
                for (const src of scriptSources) {
                    await loadScript(src);
                }
            } catch (error) {
                console.error(error);
            }
        };

        loadScripts();

        // Cleanup function to remove scripts
        return () => {
            const allScripts = [jqueryScript, ...scriptSources];
            allScripts.forEach((src) => {
                const script = document.querySelector(`script[src="${src}"]`);
                if (script) {
                    document.body.removeChild(script);
                }
            });
        };
    }, []);

    return null;
};

export default Scripts;
