import { React } from "react";
import { useTranslation } from 'react-i18next';

const Faqs = () => {
    const { t } = useTranslation();
    return (
        <section className="mt-5 srex-faq-three srex-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="srex-faq-three__left">
                            <div className="srex-faq-three__left__img">
                                <img src="/images/web/werespect/persona-contenta.png" alt="FAQ M" />
                            </div>
                            <div className="gap-3 srex-faq-three__left__box d-flex wow fadeInUp" data-wow-duration="1.3s">
                                <img
                                    src="/images/web/werespect/medal-green.png"
                                    alt="Medal"
                                />
                                <div className="srex-faq-three__left__box__text">
                                    <h2 style={{ textTransform: "none" }}>weRespect</h2>
                                    <p>{t("Comprometidas con el cambio sostenible del sector.")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-0 col-lg-6 col-12">
                        <div className="srex-section__head">
                            <h5 className="srex-section__head__badge wow ud-fade-in-up" data-wow-delay="200ms">{t("Nuestra Historia")}</h5>
                            <h2 className="srex-section__head__title wow ud-fade-in-up" data-wow-delay="200ms">
                                {t("¿Quiénes somos?")}
                            </h2>
                            <div className="srex-accordion accordion" id="faq">
                                <div className="accordion-item">
                                    <div className="accordion-header">
                                        <div className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" role="button">
                                            <div className="accordion-header__left">
                                                <p className="accordion-header__left__badge">01</p>
                                                <h3>{t("Nuestra misión")}</h3>
                                            </div>
                                            <i className="fa-solid fa-angle-down open"></i>
                                            <i className="fa-solid fa-angle-up close"></i>
                                        </div>
                                    </div>
                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#faq">
                                        <div className="accordion-body">
                                            {t("Potenciar el compromiso de las empresas del sector de alquileres a corto plazo por la convivencia y el impacto del turismo en las ciudades.")}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header">
                                        <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" role="button">
                                            <div className="accordion-header__left">
                                                <p className="accordion-header__left__badge">02</p>
                                                <h3>{t("Nuestra visión")}</h3>
                                            </div>
                                            <i className="fa-solid fa-angle-down open"></i>
                                            <i className="fa-solid fa-angle-up close"></i>
                                        </div>
                                    </div>
                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#faq">
                                        <div className="accordion-body">
                                            {t("Que todas las ciudades turísticas del mundo tengan apartamentos de uso turístico comprometidos con la convivencia vecinal y el turismo sostenible.")}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header">
                                        <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo" role="button">
                                            <div className="accordion-header__left">
                                                <p className="accordion-header__left__badge">03</p>
                                                <h3>{t("Convivencia")}</h3>
                                            </div>
                                            <i className="fa-solid fa-angle-down open"></i>
                                            <i className="fa-solid fa-angle-up close"></i>
                                        </div>
                                    </div>
                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#faq">
                                        <div className="accordion-body">
                                            {t("Promover la reducción y/o eliminación del impacto negativo de las viviendas turísticas en sus entornos vecinales reduciendo de ese modo la conflictividad social.")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Faqs;
