import { React } from "react";
import { useTranslation } from 'react-i18next';

const OtrosDestacados = ({ lang }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="srex-service-two srex-section">
            <img src="/images/web/werespect/werespect.png" alt="Frame" className="srex-service-two__frame wow ud-fade-in-right" data-wow-delay="200ms" style={{ width: "117px" }} />
            <div className="container" >
                <div className="text-center srex-section__head wow ud-fade-in-up" data-wow-delay="200ms">
                    <h5 className="srex-section__head__badge">
                        {t("El certificado")}
                    </h5>
                    <h2 className="srex-section__head__title" style={{ textTransform: "none" }}>
                        {t("Ventajas weRespect")}
                    </h2>
                </div>
                <div className="srex-info-box">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay="200ms">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="srex-info-box__item__img">
                                        <i className="fa-thin fa-leaf-heart" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                    </div>
                                </div>
                                <h3 className="srex-info-box__item__text">
                                    {t("Sostenibilidad")}
                                </h3>
                                <p className="srex-info-box__item__desc">{t("Cada vez existe una mayor conciencia sobre la sostenibilidad social y ambiental de la actividad económica en general y del turismo en particular.")}</p>
                                <div className="srex-info-box__more">
                                    <a href=""
                                    >{t("Más información")}
                                        <i className="fa-solid fa-arrow-right"></i
                                        ></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay="400ms">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="srex-info-box__item__img">
                                        <i className="fa-thin fa-circle-up" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                    </div>
                                </div>
                                <h3 className="srex-info-box__item__text">
                                    {t("Beneficios")}
                                </h3>
                                <p className="srex-info-box__item__desc">{t("Los negocios que adoptan medidas que promuevan unas prácticas más sostenibles se benefician del reconocimiento por parte de la sociedad y de sus propios clientes.")}</p>
                                <div className="srex-info-box__more">
                                    <a href="">
                                        {t("Más información")}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay="600ms">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="srex-info-box__item__img">
                                        <i className="fa-thin fa-award" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                    </div>
                                </div>
                                <h3 className="srex-info-box__item__text">
                                    {t("Reconocimiento")}
                                </h3>
                                <p className="srex-info-box__item__desc">{t("Referente de la sostenibilidad social en el sector de alquiler vacacional dentro de la ciudad, de cara a vecinos y administraciones.")}</p>
                                <div className="srex-info-box__more">
                                    <a href="">
                                        {t("Más información")}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="srex-stats srex-stats--two">
                    <div className="container">
                        <div className="srex-stats__items wow ud-fade-in-up" data-wow-delay="200ms">
                            <div className="row">
                                <div className="col-md-6 col-lg-3 col-12">
                                    <div className="srex-stats__item">
                                        <div className="srex-stats__item__icon">
                                            <i className="fa-thin fa-hand-holding-heart" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                        </div>
                                        <div className="srex-stats__item__text">
                                            <h2><span className="counter">80</span>+</h2>
                                            <h3>{t("Miembros y partners")}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 col-12">
                                    <div className="srex-stats__item">
                                        <div className="srex-stats__item__icon">
                                            <i className="fa-thin fa-users" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                        </div>
                                        <div className="srex-stats__item__text">
                                            <h2><span className="counter">180</span>+</h2>
                                            <h3>{t("Eventos")}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 col-12">
                                    <div className="srex-stats__item">
                                        <div className="srex-stats__item__icon">
                                            <i className="fa-thin fa-screen-users" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                        </div>
                                        <div className="srex-stats__item__text">
                                            <h2><span className="counter">300</span>+</h2>
                                            <h3>{t("Formaciones")}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 col-12">
                                    <div className="srex-stats__item">
                                        <div className="srex-stats__item__icon">
                                            <i className="fa-thin fa-house-heart" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                        </div>
                                        <div className="srex-stats__item__text">
                                            <h2><span className="counter">390</span>+</h2>
                                            <h3>{t("Empresas certificadas")}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}
export default OtrosDestacados;

